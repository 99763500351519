import {useState} from "react";
import Box from '@mui/material/Box';
import {
  DataGridPro, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector,
} from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import useFRead from "../../controller/firebase/hook/useFRead";
import {useDateContext} from "../../controller/context/DateContext";
import Tour from "../../model/Tour";
import {useGuideContext} from "../../controller/context/GuideContext";


const operationToTourList = (date) => (operationVal) => {
  if (!operationVal) return [];
  return Object.entries(operationVal).map(([tourId, rawTour]) => new Tour(rawTour, tourId, `/operation/${date}/tours`));
};

function CustomGridTreeDataGroupingCell(props) {
  const {id, field, rowNode, formattedValue,} = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };
  return (
      <Box sx={{ml: rowNode.depth * 4}}>
        <div>
          {filteredDescendantCount > 0 ? (
              <Button onClick={handleClick} tabIndex={-1} size="small">
                {">"} {formattedValue}
              </Button>
          ) : (
              <span/>
          )}
        </div>
      </Box>
  );
}

export default function BusPage() {
  const {guideMap} = useGuideContext()
  const {date, dashFormat} = useDateContext();
  const {
    isLoading: productLoading,
    data: productMap,
  } = useFRead(`/product`, (rawProduct) => new Map(Object.values(rawProduct).map((product) => [product.id, product])), new Map());

  const {
    isLoading,
    data: tours,
    error
  } = useFRead(`/operation/${dashFormat}/tours`, operationToTourList(dashFormat), []);

  const groupingColDef = {
    headerName: 'Group',
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params}/>
  };

  const teamRows =
      Object.entries(
          tours
              .reduce((result, tour) => {
                console.log(tour);
                if (!result[tour.area])
                  result[tour.area] = []
                result[tour.area].push(tour)
                return result;
              }, {})
      )
          .map(([area, tours]) => {
            const areaRow = {
              id: area,
              type: 'AREA',
              date: dashFormat,
              path: [area],
              tourTeam: '',
              team: tours.map(t => Object.values(t.teams ?? {}).length).reduce((a, b) => a + b)
            }
            const tourRows = tours
                .filter(tour => Object.values(tour.teams ?? {})
                    .filter(team => Object.values(team.reservations ?? {}).length > 0)
                    .length > 0
                )
                .map((tour) => {
                  const productId = tour.productId;
                  const productEntity = productMap?.[productId];
                  const product = productEntity?.name ?? tour.product;
                  const tourPath = [area, product];
                  const teams = Object.values(tour.teams);

                  const tourRow = {
                    id: tour.id,
                    type: 'TOUR',
                    date: dashFormat,
                    path: tourPath,
                    tourTeam: `팀 ${teams.length}개`,
                    people: teams.length > 0 ? `${teams.map(t => t.people).reduce((a, b) => a + b)} 명` : `0 명`
                  }

                  const teamRows = teams.map((team, idx) => {
                    const guides = team.guides.map(id => guideMap.get(id)?.name ?? 'Unknown Guide').join(', ');
                    return ({
                      id: team.id,
                      type: 'TEAM',
                      date: dashFormat,
                      path: [...tourPath, `${product} - ${idx + 1}팀`],
                      tourTeam: `${idx + 1}팀(${guides})`,
                      people: `${team.people} 명`,
                    });
                  });
                  return ([
                    tourRow,
                    ...teamRows
                  ])
                }).flat(1)
            return ([
              ...tourRows
            ])
          }).flat(1)


  const columns = [
    {field: 'date', width: 250},
    {name: 'tour-team', field: 'tourTeam', width: 250},
    {field: 'people', width: 250},
  ]

  return (
      <Box>
        <DataGridPro
            treeData
            rows={teamRows}
            columns={columns}
            groupingColDef={groupingColDef}
            getTreeDataPath={(r) => r.path}
        />

      </Box>
  )
}
