import {Box, Button, CircularProgress, SwipeableDrawer, SwipeableDrawerProps} from '@mui/material';
import {red} from '@mui/material/colors';
import DeleteForeverIcon from '@mui/icons-material/Close';
import {ChangeEvent, Dispatch, SetStateAction, useContext, useState} from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import SendIcon from '@mui/icons-material/Send';
import {useLocation, useParams} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {sendMessage} from '../../../controller/chat';
import {uploadFileToStorage} from '../../../controller/chat/firebase';
import Puller from '../../ui/Puller';
import {GuideContext} from '../../../controller/context/GuideContext';
import {IGuide, IParticipant} from '../../../types/chat';
import Typography from "@mui/material/Typography";
import heic2any from "heic2any";
import IconButton from "@mui/material/IconButton";
import {captureException} from "@sentry/react";


interface Props extends SwipeableDrawerProps {
  participants: IParticipant[];
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ImageUploadSwiper({setOpen, participants, ...props}: Props) {
  const [files, setFiles] = useState<(File | Blob)[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [progresses, setProgresses] = useState<number[]>([]);
  const [key, setNewKey] = useState<string>(new Date().toString());
  const location = useLocation();

  const {guide} = useContext(GuideContext) as any;
  const {chatId} = useParams() as { chatId: string };

  async function onUpload() {
    if (files.length === 0) return;
    setIsUploading(true);

    const fileUrls = await uploadFilesToStorage(files);
    const message = {
      type: 'image',
      files: fileUrls,
    };

    await sendMessage(chatId, participants, guide, message);
    setNewKey(new Date().toString());
    setProgresses([]);
    setFiles([]);
    setOpen(false);
    setIsUploading(false);
  }

  async function uploadFilesToStorage(files: Blob[]): Promise<string[]> {
    const results: string[] = [];
    const batchSize = 5;

    for (let i = 0; i < files.length; i += batchSize) {
      const batch: Blob[] = files.slice(i, i + batchSize);

      const batchResults: string[] = await Promise.all(
        batch.map((file, idx): Promise<string> => {
          // 전체 파일 배열에서의 인덱스를 계산합니다.
          const fileIndex = i + idx;

          // 업로드 시도를 함수로 분리하면 재시도 로직을 깔끔하게 구성할 수 있습니다.
          const attemptUpload = (): Promise<string> =>
            uploadFileToStorage(
              `chats/${chatId}/${uuidv4()}`,
              file,
              (newProgress: number, transferred: number, total: number) => {
                setProgresses((progresses: number[]): number[] => {
                  const newProgresses = [...progresses];
                  newProgresses[fileIndex] = newProgress;
                  return newProgresses;
                });
              }
            );

          // 첫 번째 시도 후 실패하면 재시도, 그래도 실패 시 에러 처리합니다.
          return attemptUpload()
            .catch(async (e: unknown): Promise<string> => {
              // 첫 번째 실패 시, 에러 기록 후 재시도
              const error = e instanceof Error ? e : new Error(String(e));
              captureException(error);
              return attemptUpload();
            })
            .catch((e: unknown): string => {
              // 두 번째 시도에서도 실패하면, 에러 기록 후 사용자에게 알림
              const error = e instanceof Error ? e : new Error(String(e));
              captureException(error);
              alert(`네트워크 이슈로 일부 파일의 전송에 실패했습니다. 확인 후 다시 보내주세요.`);
              return ''; // 에러 발생 시 빈 문자열 반환
            });
        })
      );

      results.push(...batchResults);
    }

    return results;
  }



  function changeFile(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files instanceof FileList) {
      const targetFiles = [...files, ...e.target.files];
      setIsConverting(true);
      Promise.all(targetFiles.map(async (file) => {
        if (file.type === 'image/heic' || file.type === 'image/heif') {
          const uploadable = await heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: 1,
          });
          return Array.isArray(uploadable) ? uploadable[0] : uploadable
        }
        return file;
      })).then((files) => setFiles(files))
        .finally(() => setIsConverting(false));
    }
  }

  function disableSwipeOnChildren(e: any) {
    e.stopPropagation();
  }

  return (
    <SwipeableDrawer {...props}>
      <Box
        sx={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          py: 2,
        }}
      >
        <Puller/>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          px: 2.5,
        }}
        onTouchStart={disableSwipeOnChildren}
        onMouseDown={disableSwipeOnChildren}
      >
        <Box
          sx={{
            display: 'flex',
            columnGap: '12px',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            component="label"
            variant="contained"
            disabled={isUploading || isConverting}
            startIcon={(isUploading || isConverting) ? <CircularProgress size={20} color="inherit"/> :
              <FolderIcon/>}
            sx={{
              background: red[500],
            }}
          >
            {isConverting ? '...Converting' : 'Find'}
            <input
              key={key}
              type="file"
              id="file"
              style={{display: 'none'}}
              accept={'image/*'}
              multiple
              onChange={changeFile}
            />
          </Button>
          {
            files.length > 0
            &&
              <Button
                  variant="contained"
                  disabled={isUploading}
                  startIcon={isUploading ? <CircularProgress size={20} color="inherit"/> : <SendIcon/>}
                  onClick={onUpload}
              >
                SEND
              </Button>
          }
        </Box>
        <Box
          sx={{
            overflowX: 'scroll',
            display: 'flex',
            columnGap: '4px',
          }}
        >
          {files.map((file, idx) => (
            <Box
              sx={{
                position: 'relative',
                border: '1px solid  #e2e2e2',
              }}
            >
              <img
                src={URL.createObjectURL(file)}
                style={{
                  width: '200px',
                  height: '200px',
                  objectFit: 'cover',
                }}
                alt="image"
              />
              <IconButton
                sx={{
                  background: 'rgba(0,0,0,0.6)',
                  transform: 'scale(0.75)',
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  cursor: 'pointer',
                }}
              >
                <DeleteForeverIcon
                  sx={{
                    color: 'white'
                  }}
                  onClick={() => {
                    const filterFiles = files.filter((f) => f !== file);
                    setFiles(filterFiles);
                  }}
                />
              </IconButton>
              {
                progresses[idx] !== undefined
                  ? <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '200px',
                    height: '200px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'rgba(0,0,0,0.65)'
                  }}>
                    <Typography color={'white'}>
                      {Math.round(progresses[idx])}%
                    </Typography>
                  </Box>
                  : null
              }
            </Box>
          ))}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
}
